<template>
  <v-row justify="center">
    <v-dialog light v-model="registerPartnerDilog" persistent max-width="1200" scrollable>
      <v-card>
        <v-card-title class="headline px-2">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0">
              {{ formData.id ? "Editar provedor" : "Novo provedor" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn @click="toogleRegisterPartnerDilog" class="mt-2" color="red" fab icon dark x-small absolute top right>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tabs">
            <v-tabs-slider class="pa-5"></v-tabs-slider>
            <v-tab class="partnerDataTab"> Dados do provedor </v-tab>
            <!-- <v-tab class=""> Endereço do provedor </v-tab> -->
            <v-tab class="contactTab"> Contatos do provedor </v-tab>
            <v-tab class="credentialsAppTab" :disabled="!formData.id">
              Credenciais de aplicativos
            </v-tab>
            <v-tab class="erpDataTab" :disabled="!formData.erp">
              Dados do ERP
            </v-tab>
            <v-tab class="" :disabled="!idpCredencial.length">
              Credenciais IDP
            </v-tab>
          </v-tabs>
          <v-tabs-items light v-model="tabs" style="min-height: 385px; border-top: 1px solid #ccc">
            <v-tab-item>
              <v-card flat>
                <v-card-text class="px-0"><v-form ref="partnerForm" v-model="valid">
                    <v-row>
                      <v-col class="pt-0 pb-5" cols="12" sm="6" md="6">
                        <v-switch persistent-hint hint="Habilete a opção 'Integração ERP' e preencha os campos
                          de integração para utilizarmos o seu ERP." @change="setTabErp" class="labelTitle ml-2"
                          v-model="formData.erp" color="primary" label="Integração ERP"></v-switch>
                      </v-col>
                      <v-col cols="12" sm="1" md="1"></v-col>
                      <v-col class="pt-0 pb-5 pl-9" cols="12" sm="5" md="5">
                        <v-checkbox persistent-hint hint="Com essa opção ativada serão gerados contratos para o provedor."
                          v-model="formData.create_contract" label="Gerar contratos"></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field v-model="formData.name" :rules="$store.state.formRules.required"
                            label="Nome/Razão social" required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field maxlength="60" v-model="formData.fantasy_name" :rules="$store.state.formRules.required"
                            label="Nome fantasia" required></v-text-field>
                        </v-col>

                        <!-- <v-col cols="12" md="4">
                          <v-text-field maxlength="30" persistent-hint hint="Apelido / Nome do provedor dentro do sistema"
                            v-model="formData.prefix" :rules="$store.state.formRules.required" label="Prefixo"
                            required></v-text-field>
                        </v-col> -->

                        <v-col cols="12" md="2">
                          <div>
                            <v-text-field-simplemask v-model="formData.document" v-bind:label="`CPF/CNPJ`"
                              v-bind:properties="{
                                rules: [
                                  $store.state.formRules.cpfCnpjRules[0](
                                    formData.document ? formData.document : ''
                                  ),
                                ],
                                prefix: '',
                                suffix: '',
                                readonly: false,
                                disabled: false,
                                outlined: false,
                                clearable: false,
                                placeholder: '',
                              }" v-bind:options="{
  inputMask:
    (formData.document
      ? formData.document.length
      : '') <= 11
      ? '###.###.###-########'
      : '##.###.###/####-##',
  outputMask: '##############',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
                          </div>
                        </v-col>
                        <v-col cols="12" md="2">
                          <div>
                            <v-text-field-simplemask v-model="formData.zip_code" v-bind:label="`CEP`" v-bind:properties="{
                              rules: [
                                $store.state.formRules.cepRules[0](
                                  formData.zip_code
                                ),
                              ],
                              prefix: '',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              placeholder: '',
                            }" v-bind:options="{
  inputMask: '#####-###',
  outputMask: '########',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
                          </div>
                        </v-col>
                        <v-col cols="12" md="7">
                          <v-text-field v-model="formData.address" :rules="$store.state.formRules.required"
                            label="Endereço" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="formData.number" :rules="$store.state.formRules.numberRules"
                            label="Número" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.complement" label="Complemento" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.neighborhood" :rules="$store.state.formRules.required"
                            label="Bairro" required></v-text-field>
                        </v-col>
                        <v-col class="" cols="12" md="2">
                          <!-- :hint="`${select.state}, ${select.abbr}`" -->
                          <v-select light :rules="$store.state.formRules.required" v-model="stateSel"
                            :items="$store.state.ufBrasil.UF" item-text="state" item-value="state" label="UF"
                            return-object></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.city" :rules="$store.state.formRules.required" label="Cidade"
                            required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <div>
                            <v-text-field-simplemask v-model="formData.phone" v-bind:label="'Telefone'" v-bind:properties="{
                              rules: [
                                $store.state.formRules.phoneRules[0](
                                  formData.phone
                                ),
                              ],
                              prefix: '',
                              suffix: '',
                              readonly: false,
                              disabled: false,
                              outlined: false,
                              clearable: false,
                              placeholder: '',
                            }" v-bind:options="{
  inputMask:
    (formData.phone
      ? formData.phone.length
      : '') < 11
      ? '(##) ####-#####'
      : '(##) # ####-####',
  outputMask: '############',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container> </v-form></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text class="mt-5 pt-5">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="toogleContactFormDialog" v-bind="attrs" v-on="on" class="mt-2" color="primary" fab
                        dark x-small absolute top right>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span class="miniTtip">Novo contato</span>
                  </v-tooltip>
                  <ContactList :contacts="contacts" :editContact="editContact" :confirmDelContact="confirmDelContact" />
                  <ContactForm :services="services" :editContactData="editContactData" :partnerId="formData.id"
                    :updateContactList="updateContactList" :toogleContactFormDialog="toogleContactFormDialog"
                    :contactFormDialog="contactFormDialog" />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <!-- -->
              <!--  -->
              <!--  -->
              <v-row class="pt-6 px-4">
                <v-col class="pb-0" cols="12" sm="3">
                  <v-card flat class="pt-3 font-weight-bold">
                    Adicione as credenciais de APPs
                  </v-card>
                </v-col>
                <v-col class="pb-0" cols="12" sm="4">
                  <v-card flat class="">
                    Adicione e remova credenciais e ao final clique no botão
                    "SALVAR" para atualizar o provedor.
                  </v-card>
                </v-col>
                <v-col class="pb-0" cols="12" sm="5"></v-col>
                <v-col class="pt-3" cols="12" sm="5">
                  <v-combobox item-text="name" item-value="id" v-model="selectedService" :items="services"
                    label="Selecione o App"></v-combobox>
                </v-col>
                <v-col class="pt-3" cols="12" sm="5">
                  <v-text-field v-model="apikey" label="Api key"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="success" fab x-small dark @click="setCredencialApi">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span class="miniTtip">Adicionar Credencial</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-data-table :headers="headersAppCredencials" :items="app_credencials" :items-per-page="5"
                class="elevation-0">
                <template v-slot:item.actions="{ item }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="
                        confirmDelCredencial(
                          item,
                          app_credencials.indexOf(item)
                        )
                        " x-small color="red" dark><v-icon center x-small>
                          mdi-delete-forever
                        </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Excluir credencial</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item :disabled="!formData.erp">
              <v-card flat>
                <v-card-text class="px-0"><v-form ref="erpDataForm" v-model="validErpData">
                    <v-container>
                      <v-col class="pt-0 pb-7 px-0" cols="12" sm="12" md="12">
                        <v-card flat>
                          Habilete a opção 'Integração ERP' e preencha os campos
                          de integração para utilizarmos o seu ERP.
                        </v-card>
                      </v-col>
                      <v-row>
                        <!-- :rules="$store.state.formRules.required" -->
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.username" label="Nome de usuário" required></v-text-field>
                        </v-col>
                        <!-- :rules="$store.state.formRules.required" -->
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.password" label="Senha" required></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <!-- :rules="$store.state.formRules.required" -->
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.client_id" label="Client ID" required></v-text-field>
                        </v-col>
                        <!-- :rules="$store.state.formRules.required" -->
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.client_secret" label="Client Secret" required></v-text-field>
                        </v-col>
                        <!-- :rules="$store.state.formRules.required" -->
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.endpoint" label="Endpoint" required></v-text-field>
                        </v-col>
                        <!-- :rules="$store.state.formRules.required" -->
                        <v-col cols="12" md="6">
                          <v-text-field v-model="formData.endpoint_query" label="Endpoint de consulta"
                            required></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" md="4">
                          <v-text-field
                            v-model="formData.route"
                            :rules="$store.state.formRules.required"
                            label="Route"
                            required
                          ></v-text-field>
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <IdpCredencial :idpCredencial="idpCredencial" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="toogleRegisterPartnerDilog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="savePartner"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const resetPartnerForm = {
  id: null,
  endpoint_query: "",
  endpoint: "",
  name: "",
  fantasy_name: "",
  prefix: "",
  document: "",
  phone: "",
  address: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  zip_code: "",
  erp: false,
  client_id: "",
  client_secret: "",
  username: "",
  password: "",
  route: "",
  create_contract: true
};
import ContactList from "./contacts/ContactsList.vue";
import ContactForm from "./contacts/ContacForm.vue";
import IdpCredencial from "./IdpCredencial.vue";
export default {
  components: {
    IdpCredencial,
    ContactList,
    ContactForm,
  },
  watch: {
    registerPartnerDilog(v) {
      console.log("aqtyu");
      if (!v) {
        this.idpCredencial = [];
        this.$refs.partnerForm.resetValidation();
        this.stateSel = "";
        this.app_credencials = [];
        this.formData = { ...resetPartnerForm };
        this.tabs = 0;
        this.getProviders();
      } else {
        if (this.editPartnerData) {
          this.getIdpCredencial(this.editPartnerData.route);
          this.app_credencials = [...this.editPartnerData.app_credencials];
          this.stateSel = this.editPartnerData.state;
          this.formData = { ...this.editPartnerData };
        }
      }
    },
  },
  mounted() {
    this.getServices();
  },
  methods: {
    getIdpCredencial(route) {
      var obj = {
        url: "/api/partner/getCredentials-app",
        query: { route: route },
        noLoader: true,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        console.log("kh", response);
        if (Array.isArray(response.data)) {
          this.idpCredencial = [...response.data];
        } else {
          this.idpCredencial.push(response.data);
        }
      });
    },
    delCredencial(i) {
      this.app_credencials.splice(i, 1);
    },
    confirmDelCredencial(data, i) {
      let obj = {
        message: `Você deseja excluir a credencial <b>${data.name}</b>?`,
        title: "Excluir Credencial",
        callback: (_) => {
          this.delCredencial(i);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    setCredencialApi() {
      console.log("api", this.selectedService, this.apikey);
      let obj = {
        name: "",
        service_id: "",
        api_key: "",
      };
      obj.name = this.selectedService.name;
      obj.service_id = this.selectedService.id;
      obj.api_key = this.apikey;
      // credentials_json: {apikey: "directv_durand_apikey"}
      obj.credentials_json = { apikey: this.apikey };
      this.app_credencials.push({ ...obj });
      this.selectedService = "";
      this.apikey = "";
    },
    getServices() {
      var obj = {
        url: "/api/service/get-all",
        query: null,
        noLoader: true,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.services = [...response.data];
      });
    },
    setTabErp() {
      if (this.formData.erp) {
        document.querySelector(".erpDataTab").click();
        this.$refs.partnerForm.resetValidation();
      }
    },
    editContact(data, ind) {
      //console.log(ind);
      //return
      this.editContactData = { ...data };
      this.editContactData.index = ind;
      this.contactFormDialog = true;
    },
    toogleContactFormDialog() {
      this.editContactData = null;
      this.contactFormDialog = !this.contactFormDialog;
    },
    savePartner() {
      console.log("save");
      if (!this.$refs.partnerForm.validate()) {
        document.querySelector(".partnerDataTab").click();
        return;
      }
      if (!this.contacts.length) {
        document.querySelector(".contactTab").click();
        let opts = {
          message: "É necessário cadastrar ao menos um contato.",
          type: "warning",
        };
        this.$store.commit("showAlert", opts);
        return;
      }
      if (this.$refs.erpDataForm) {
        if (this.formData.erp && !this.$refs.erpDataForm.validate()) {
          document.querySelector(".erpDataTab").click();
          return;
        }
      }
      if (this.app_credencials.length) {
        this.formData.app_credencials = [...this.app_credencials];
      } else {
        this.formData.app_credencials = [];
      }
      this.formData.state = this.stateSel.state;
      this.formData.partner_contacts = this.contacts;
      let url = this.formData.id
        ? "/api/partner/update"
        : "/api/partner/create";
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Provedor ${this.formData.id ? "atualizado" : "criado"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.updatePartners(response.data);
        this.toogleRegisterPartnerDilog();
      });
    },
    getColor(status) {
      if (status == 2) return "red";
      else if (status == 1) return "orange";
      else return "green";
    },
    getIcon(status) {
      if (status == 2) return "highlight_off";
      else if (status == 1) return "priority_high";
      else return "check_circle_outline";
    },
  },
  props: {
    getProviders: Function,
    updatePartners: Function,
    editPartnerData: Object,
    registerPartnerDilog: Boolean,
    toogleRegisterPartnerDilog: Function,
    updateContactList: Function,
    confirmDelContact: Function,
    contacts: Array,
  },
  data() {
    return {
      idpCredencial: [],
      selectedService: "",
      services: [],
      apikey: "",
      headersAppCredencials: [
        {
          text: "APP",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Api key",
          align: "start",
          sortable: false,
          value: "credentials_json.apikey",
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      app_credencials: [],
      editContactData: null,
      contactFormDialog: false,
      stateSel: null,
      valid: true,
      validErpData: true,
      formData: { ...resetPartnerForm },
      tabs: null,
    };
  },
};
</script>

<style>
.absolutButtons {
  position: absolute;
  right: 20px;
  top: 35px;
}
</style>